import { MAPBOX_PLACES_BASE_URL } from '@/costants/';
import { CLIENT_ENV } from '@/config/configuration';

export const geocodeBySearchLocation = async (location: string) => {
  const response = await fetch(
    `${MAPBOX_PLACES_BASE_URL}/${location}.json?access_token=${
      CLIENT_ENV().NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN
    }&language=it&country=it&types=place,address`,
  );
  const data = await response.json();
  const latitude = data?.features?.[0]?.geometry?.coordinates?.[1];
  const longitude = data?.features?.[0]?.geometry?.coordinates?.[0];
  return {
    latitude,
    longitude,
  };
};

export const placesByLocationName = async (location: string) => {
  const response = await fetch(
    `${MAPBOX_PLACES_BASE_URL}/${location}.json?access_token=${
      CLIENT_ENV().NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN
    }&limit=10&language=it&country=it&types=place,address`,
  );
  const data = await response.json();
  return data?.features ?? [];
};

export const getUserLocation = async (): Promise<{ userLocationName?: string; userCoordinates?: number[] } | undefined> => {
  const { state } = await navigator.permissions.query({ name: 'geolocation' });

  if (state === 'denied') {
    alert('Accesso alla geolocalizzazione negato.');
    return;
  }

  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(
      async position => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(
            `${MAPBOX_PLACES_BASE_URL}/${longitude},${latitude}.json?access_token=${CLIENT_ENV().NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}`,
          );
          const data = await response.json();
          resolve({
            userLocationName: data.features[0].place_name,
            userCoordinates: [latitude, longitude],
          });
        } catch (error) {
          alert('Errore in fase di ottenimento geolocalizzazione utente');
          resolve(undefined);
        }
      },
      () => {
        alert('Accesso alla geolocalizzazione negato.');
        resolve(undefined);
      },
    );
  });
};
